'use client'

import {eachDayOfInterval, isSameDay, isToday} from 'date-fns'
import {lazy, useCallback, useEffect, useState} from 'react'
import useEmblaCarousel, {EmblaOptionsType} from 'embla-carousel-react'

import ClassNames from 'embla-carousel-class-names'
import {Icon} from 'layout/Icon'
import css from './Events.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type Events = {date: string; events: {title: string; url: string}[]}[]

const EventsDayPreview = lazy(() =>
	import('./EventsDay').then((mod) => ({default: mod.EventsDay}))
)

export const Events: React.FC<{
	events: Events
	interval: {start: string; end: string}
}> = ({events, interval}) => {
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
	const [dateRange, setdateRange] = useState(
		eachDayOfInterval({
			start: new Date(interval.start),
			end: new Date(interval.end)
		})
	)

	const convertedEvents = events.map((event) => ({
		...event,
		date: new Date(event.date)
	}))

	const emblaOptions: EmblaOptionsType = {
		dragFree: true,
		slidesToScroll: 'auto',
		containScroll: 'trimSnaps',
		inViewThreshold: 0
	}
	const [emblaRef, emblaApi] = useEmblaCarousel(emblaOptions, [ClassNames()])
	const scrollPrev = useCallback(
		() => emblaApi && emblaApi.scrollPrev(),
		[emblaApi]
	)
	const scrollNext = useCallback(
		() => emblaApi && emblaApi.scrollNext(),
		[emblaApi]
	)
	const scrollTo = useCallback(
		(index: number) => emblaApi && emblaApi.scrollTo(index, true),
		[emblaApi]
	)

	const onSelect = useCallback(() => {
		if (!emblaApi) return

		setPrevBtnEnabled(emblaApi.canScrollPrev())
		setNextBtnEnabled(emblaApi.canScrollNext())
	}, [emblaApi, setPrevBtnEnabled, setNextBtnEnabled])

	useEffect(() => {
		if (!emblaApi) return
		const totalSnaps: number = emblaApi.scrollSnapList().length
		const totalSnapNodes: number = dateRange.length
		const nodesPerSnap: number = Math.floor(totalSnapNodes / totalSnaps)
		const todayIndex: number = dateRange.findIndex((date) => isToday(date))
		const todaySnapIndex: number = Math.floor(todayIndex / nodesPerSnap)
		scrollTo(todaySnapIndex || 0)
		onSelect()
		emblaApi.on('select', onSelect)
		emblaApi.on('reInit', onSelect)
	}, [emblaApi, onSelect, scrollTo, dateRange])

	return (
		<div className={styles.events()}>
			<button
				onClick={scrollPrev}
				className={styles.events.prev.is({
					disabled: !prevBtnEnabled
				})()}
				title={!prevBtnEnabled ? '' : 'Vorige periode'}
			>
				<Icon icon="arrowLeft" />
			</button>
			<div className={styles.events.days()}>
				<div className={styles.events.days.embla()} ref={emblaRef}>
					<div className={styles.events.days.embla.container()}>
						{dateRange.map((date, index) => (
							<div
								className={styles.events.days.embla.container.slide()}
								key={index}
							>
								<EventsDayPreview
									date={date.getTime()}
									events={
										convertedEvents.find((event) => isSameDay(event.date, date))
											?.events || []
									}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
			<button
				onClick={scrollNext}
				className={styles.events.next.is({
					disabled: !nextBtnEnabled
				})()}
				title={!nextBtnEnabled ? '' : 'Volgende periode'}
			>
				<Icon icon="arrowRight" />
			</button>
		</div>
	)
}
